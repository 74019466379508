import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RosService } from 'src/app/_services/ros.service';
import { ROS_DOWNLOAD_KEYS } from 'src/app/_constants/ros.constants'; // Update the path as needed
import { IROSExcelRequestViewModel } from 'src/app/_models/ros.models'; // Update if needed

@Component({
  selector: 'tg-excel-export-button',
  templateUrl: './excel-export-button.component.html',
  styleUrls: ['./excel-export-button.component.scss'] // or .css if preferred
})
export class ExcelExportButtonComponent {

  /**
   * Whether the button is disabled.
   */
  @Input() btnDisabled: boolean = false;

  /**
   * The request parameters (body) for the Excel export.
   */
  @Input() requestParams: any = {};

  /**
   * Transaction identifier (mandatory).
   * Default value from your constants.
   */
  @Input() transactionIdentifier: string = ROS_DOWNLOAD_KEYS.INVOICE_QUEUE.TRANSACTION_IDENTIFIER;

  /**
   * Transaction identifier name (mandatory).
   * Default value from your constants.
   */
  @Input() transactionIdentifierName: string = ROS_DOWNLOAD_KEYS.INVOICE_QUEUE.TRANSACTION_IDENTIFIER_NAME;

  /**
   * The URL endpoint for triggering report generation.
   */
  @Input() reportUrl: string = '';

  /**
   * Header mapping for the Excel file.
   */
  @Input() headerMapping: any = ROS_DOWNLOAD_KEYS.INVOICE_QUEUE.HEADER_MAPPINGS;

  /**
   * Receiver email for the exported file.
   */
  @Input() receiverEmail: string = '';

  /**
   * The HTTP method to use.
   */
  @Input() requestMethod: string | null = null;

  /**
   * Indicates if the data is paginated.
   */
  @Input() isPaginated: boolean = true;

  /**
   * Report key path.
   */
  @Input() reportKeyPath: string | null = null;

  /**
   * Any additional options to override defaults.
   */
  @Input() additionalOptions: Partial<IROSExcelRequestViewModel> = {};

  constructor(
    private toastrService: ToastrService,
    private _rosService: RosService
  ) { }

  /**
   * Called when the download button is clicked.
   * Constructs the URL if needed, then calls the RosService exportExcel method.
   */
  exportExcel(): void {
    // Use the provided reportUrl or construct a default one.
    const finalReportUrl = this.reportUrl;

    this._rosService.exportExcel(
      this.requestParams,
      this.transactionIdentifier,
      this.transactionIdentifierName,
      finalReportUrl,
      this.headerMapping,
      this.receiverEmail,
      this.requestMethod,
      this.isPaginated,
      this.reportKeyPath,
      this.additionalOptions
    ).subscribe({
      next: (res) => {
        if (res.responseCode === 202) {
          this.toastrService.warning('A request for download has already been made...');
        } else {
          this.toastrService.success('Request for download made!');
        }
      },
      error: (err) => {
        console.error(err);
        this.toastrService.error('There was an issue getting the download... please try again later');
      }
    });
  }
}