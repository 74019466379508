import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServerResponseWithBody} from "../_models/app.models";
import { IROSExcelRequestViewModel} from "../_models/ros.models";
import { QueueFilterViewModel} from "../_models/app.models";
import { AuthService} from "./auth.service";
import { RestService} from "./rest.service";
import { QueueFilterService} from "./_shared_service/queue-filter.service";

import { getUrlPathFragment} from "../_static/util";
import {
  ROS_BASE_API_PATH,
  ROS_BASE_CONSTANTS,
  ROS_DOWNLOAD_KEYS,
  ROS_EMAIL_SENDER,
  ROS_PAGINATION_DETAILS
} from "../_constants/ros.constants";

@Injectable({
  providedIn: 'root'
})
export class RosService {

  constructor(
      private _restService: RestService,
      private _authService: AuthService,
      private _queueFilterService: QueueFilterService
  ) { }

  /**
   * Export a report to Excel
   *
   * @param requestParams               The request parameters body (any).
   * @param transactionIdentifier       Mandatory transaction identifier.
   * @param transactionIdentifierName   Mandatory transaction identifier name.
   * @param reportUrl                   URL endpoint for triggering report generation.
   * @param headerMapping               Optional to select the columns that they want to show in the excel along with the name they should be written with
   * @param receiverEmail               Optional email where the Excel file will be sent. Default empty string or your own logic.
   * @param requestMethod               Optional input of method type
   * @param isPaginated                 Optional isPaginated = False
   * @param reportKeyPath
   * @param additionalOptions           Optionally override any defaults in the `IROSExcelRequestViewModel`.
   * @returns Observable<ServerResponseWithBody<any>>
   */
  exportExcel(
      requestParams: any,
      transactionIdentifier: string,
      transactionIdentifierName: string,
      reportUrl: string,
      headerMapping: {},
      receiverEmail: string = '',   // optional param
      requestMethod: string | null = null,
      isPaginated: boolean = false,
      reportKeyPath: string | null = null,
      additionalOptions: Partial<IROSExcelRequestViewModel> = {}
  ): Observable<ServerResponseWithBody<any>> {

    // Get the auth token
    const authToken: string = this._authService.getTokenSync() ?? '';

    // Build the default request object
    const defaultRequest: IROSExcelRequestViewModel = {
      // Mandatory
      serviceRequestBody: requestParams,
      transactionIdentifier,
      transactionIdentifierName,
      reportURL: reportUrl,

      // Default or example values (as per your code snippet)
      senderEmail: ROS_EMAIL_SENDER.DEFAULT,
      receiverEmail: receiverEmail,
      emailTemplateId: ROS_DOWNLOAD_KEYS.INVOICE_QUEUE.EMAIL_TEMPLATE_IDENTIFIER,
      isPaginated: isPaginated,
      paginationDetails: {
        paginationType: ROS_PAGINATION_DETAILS.TYPE_PAGE,
        pageBased: {
          pageParam: ROS_PAGINATION_DETAILS.DEFAULT_PAGE_PARAM,
          limitParam: ROS_PAGINATION_DETAILS.DEFAULT_PAGE_SIZE_PARAM,
          location: ROS_PAGINATION_DETAILS.DEFAULT_PAGE_OBJECT_KEY,
        }
      },
      requestMethod: requestMethod ?? ROS_BASE_CONSTANTS.POST,
      reportKeyPath: reportKeyPath ?? ROS_BASE_CONSTANTS.DEFAULT_RESPONSE_KEY_PATH,
      successStatusCode: "200",
      additionalMsgProps: {},
      headerMapping: headerMapping,
      serviceRequestQueryParam: {},
      serviceRequestHeaders: {
        Authorization: `Bearer ${authToken}`
      },
    };

    // Merge any additionalOptions (overriding the defaults if provided)
    const mergedRequest: IROSExcelRequestViewModel = {
      ...defaultRequest,
      ...additionalOptions,
      // Merge "serviceRequestBody" specifically if user wants to override only parts
      serviceRequestBody: {
        ...defaultRequest.serviceRequestBody,
        ...(additionalOptions?.serviceRequestBody || {})
      },
      // Merge "paginationDetails" similarly if needed, etc.
    };

    // POST call to your endpoint
    return this._restService.post<IROSExcelRequestViewModel, ServerResponseWithBody<any>>(
        getUrlPathFragment(ROS_BASE_API_PATH, 'report', 'generate-custom-report'),
        mergedRequest
    );
  }
}
