import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { forkJoin, take } from 'rxjs';
import { ITATReportQueue } from 'src/app/_models/dashboards.models';
import { DashboardToInvoiceService } from 'src/app/_services/_data-sharing/dashboard-to-invoice.service';
import { DashboardService } from 'src/app/_services/_data-sharing/dashboard.service';

interface CustomChart extends Highcharts.Chart {
  customText?: Highcharts.SVGElement;
  customTextGroup?: Highcharts.SVGElement; // Add custom property
}
@Component({
  selector: 'tg-finance-tat-report-widget',
  templateUrl: './finance-tat-report-widget.component.html',
  styleUrls: ['./finance-tat-report-widget.component.scss']
})
export class FinanceTatReportWidgetComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  @Input() centerText1!: string;
  @Input() centerText2!: string;
  @Input() value1!: string;
  @Input() value2!: string;
  @Input() isSwitchDropdown!: boolean;
  @Input() isTATByTitle!: boolean;
  @Input() id: any;
  @Input() data!: ITATReportQueue[];
  @Input() reportType!: string;

  constructor(private _dashboardToInvoice: DashboardToInvoiceService, 
    private _router: Router,
    private _dashboardDataSharingService: DashboardService
  ) { }
  
  ngOnInit(): void {
  }

  getTotalInoiceAmount(): number{
    return this.data?.reduce((total, element: ITATReportQueue) => total + (element.invoiceAmount|| 0), 0) || 0;
  }

  getTotalInoices(): number{
    return this.data?.reduce((total, element: ITATReportQueue) => total + (element.y || 0), 0) || 0;
  }

  redirectToInvoice(aging: string){
    if(this.isSwitchDropdown){
      forkJoin([
        this._dashboardDataSharingService.dropdown1$.pipe(take(1)),
        this._dashboardDataSharingService.dropdown2$.pipe(take(1))
      ]).subscribe(([value1, value2]) => {
        this._dashboardToInvoice.setAging(aging);
        this._dashboardToInvoice.setReportType(this.reportType);
        this._dashboardToInvoice.setValue1(value1);
        this._dashboardToInvoice.setValue2(value2);
        this._router.navigate(['/invoice']);
      })
    }else if(this.isTATByTitle){
      forkJoin([
        this._dashboardDataSharingService.dropdown3$.pipe(take(1)),
        this._dashboardDataSharingService.dropdown4$.pipe(take(1))
      ]).subscribe(([value1, value2]) => {
        this._dashboardToInvoice.setAging(aging);
        this._dashboardToInvoice.setReportType(this.reportType);
        this._dashboardToInvoice.setValue1(value1);
        this._dashboardToInvoice.setValue2(value2);
        this._router.navigate(['/invoice']);
      })
    }
  }
}
