<div class="flex-v-center top-nav p-3">
  <!-- Logo Section -->
  <div class="d-flex mx-3">
    <ng-content></ng-content>
  </div>

  <!-- Menu Items -->
  <div class="d-flex flex-grow-1">

    <span (click)="adminMenu.toggle($event)" *ngIf="authService.isInRole('admin') | async" class="mx-3 regular-nav-link"
      data-testid="top-navbar-admin-button">
      Admin
    </span>

    <span [routerLink]="['/master']" class="mx-3 regular-nav-link">
      Masterdata
    </span>

    <span [routerLink]="['/queue/approver']" class="mx-3 regular-nav-link"
      *ngIf="authService.isInRole('approver') | async">
      Approvals
    </span>

    <span [routerLink]="['/queue/checker']" class="mx-3 regular-nav-link"
      *ngIf="authService.isInRole('checker') | async">
      Checks
    </span>

    <span [routerLink]="['/delegation']" class="mx-3 regular-nav-link"
      *ngIf="(authService.isInRole('approver') | async) || (authService.isInRole('checker') | async)">
      Delegations
    </span>


    <!-- <span [routerLink]="['/purchase-requisition']" class="mx-3 regular-nav-link"
      *ngIf="(authService.isInRole('purchase-requisition') | async)">
      Purchase Requisition
    </span>

    <span [routerLink]="['/purchase-order']" class="mx-3 regular-nav-link"
      *ngIf="(authService.isInRole('purchase-order') | async)">
      Purchase Order
    </span>

    <span *ngIf="(authService.isInRole('user') | async) as isUser">
      <span *ngIf="(authService.isInRole('invoice') | async) as isInvoice">
        <span [routerLink]="['/invoice']" class="mx-3 regular-nav-link" *ngIf="isUser && isInvoice">
          Invoices
        </span>
      </span>
    </span> -->
    <span (click)="documentMenu.toggle($event)" class="mx-3 regular-nav-link expand-btn">
      Documents <span class="element">&#x2304;</span>
    </span>
    <span (click)="budgetMenu.toggle($event)" class="mx-3 regular-nav-link expand-btn"
      *ngIf="(authService.isInRole('budget') | async)">
      Budget <span class="element">&#x2304;</span>
    </span>


    <span [routerLink]="['/procurement']" class="mx-3 regular-nav-link"
      *ngIf="(authService.isInRole('procurement') | async)">
      Procurement
    </span>

  </div>

  <!-- Profile Logo -->
  <div class="flex-v-center me-2">
    <!-- <tg-square-button
      (click)="menu.toggle($event)"
      backgroundColorHex="#116fd6"
      [displayText]="username"
      foregroundColorHex="#eee"
      class="pointer">
    </tg-square-button> -->
    <ng-container *ngIf="profileImage; else initialsTemplate">
      <div>
        <img [src]="profileImage" alt="Company Profile Picture" class="pointer profile-image"
          (click)="menu.toggle($event)" />
      </div>
    </ng-container>
    <ng-template #initialsTemplate>
      <tg-square-button (click)="menu.toggle($event)" backgroundColorHex="#116fd6" [displayText]="username"
        foregroundColorHex="#eee" class="pointer">
      </tg-square-button>
    </ng-template>
  </div>
</div>

<p-menu #menu [model]="items" [popup]="true"></p-menu>

<p-tieredMenu #adminMenu [model]="adminMenuItems" [popup]="true"></p-tieredMenu>

<p-tieredMenu #budgetMenu [model]="budgetMenuItems" [popup]="true"></p-tieredMenu>

<p-tieredMenu #documentMenu [model]="documentMenuItem" [popup]="true"></p-tieredMenu>