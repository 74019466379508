<!--
  The template uses PrimeNG button, ripple effect, and tooltip.
  Feel free to adjust the styling or structure as needed.
-->
<button 
  [disabled]="btnDisabled"
  pButton 
  pRipple 
  class="p-button-success p-2"
  pTooltip="Download Excel"
  tooltipPosition="left"
  type="button"
  (click)="exportExcel()"
>
  <i class="bi bi-file-earmark-excel" style="font-size:22px; position: relative; left:0.15rem;"></i>
</button>