export const ROS_DOWNLOAD_KEYS = {
    INVOICE_QUEUE: {
        EMAIL_TEMPLATE_IDENTIFIER: "report_orchestration_service_template", 
        TRANSACTION_IDENTIFIER: "InvoiceQueue",
        TRANSACTION_IDENTIFIER_NAME: "Invoice",
        HEADER_MAPPINGS: {"documentIdentifier": "Document Id", "legalName": "Supplier Name", "vendorCode": "Supplier Code", "vendorGstin": "Supplier Gstin", "gstinStatus": "Gstin Status", "isOcrDone": "OCR based?", "docType": "Document Type", "docNo": "Document Number", "documentDate": "Document Date", "reportStatus": "Status", "statusRemarks": "Status Remark", "submitDate": "Date of Submission", "approvalContainerClaimAmount": "Total Amount with GST", "expenseType": "Expenditure Type", "documentGroup": "Group", "documentSubgroup": "Subgroup", "currentApproverFirstName": "Approver first name", "currentApproverLastName": "Approver last name", "paymentDate": "Payment Date", "utrNumber": "UTR No.", "lastActionedAt": "Last Actioned At", "createdDate": "Entry Date", "createdBy": "Uploaded By", "source": "Source"
        }
    },
    PURCHASE_ORDER_QUEUE: {
        EMAIL_TEMPLATE_IDENTIFIER: "report_orchestration_service_template", 
        TRANSACTION_IDENTIFIER: "PurchaseOrderQueue",
        TRANSACTION_IDENTIFIER_NAME: "PurchaseOrder",
        HEADER_MAPPINGS: {"documentIdentifier": "Document Id","vendorName": "Supplier Name", "vendorCode": "Supplier Code", "gstin": "Supplier Gstin",  "gstinStatus": "Gstin Status", "docType": "Document Type", "docNo": "Document Number", "reportStatus": "Status", "documentDate": "Document Date", "poAmount": "Total Amount with GST", "balancePoAmount": "Balance PO Amount", "expenseType": "Expenditure Type", "group": "Group", "subGroup": "Subgroup","currentApproverFirstName": "Approver first name", "currentApproverLastName": "Approver last name", "lastActionedAt": "Last Actioned At", "createdDate": "Entry Date", "createdBy": "Uploaded By"}
    },
    PURCHASE_REQUEST_QUEUE: {
        EMAIL_TEMPLATE_IDENTIFIER: "report_orchestration_service_template", 
        TRANSACTION_IDENTIFIER: "PurchaseRequestQueue",
        TRANSACTION_IDENTIFIER_NAME: "PurchaseRequest",
        HEADER_MAPPINGS: {"documentIdentifier": "Document Id", "docNo": "Document Number", "description": "Description", "reportStatus": "Status", "documentDate": "Document Date", "prAmount": "Total Amount with GST","balancePrAmount": "Balance Amount", "expenseType": "Expenditure Type",  "documentGroup": "Group", "documentSubGroup": "Subgroup", "currentApproverFirstName": "Approver first name", "currentApproverLastName": "Approver last name", "lastActionedAt": "Last Actioned At", "createdDate": "Entry Date", "createdBy": "Uploaded By", "source": "Source"}
    },
    EMPLOYEE_MASTER_QUEUE: {
        EMAIL_TEMPLATE_IDENTIFIER: "report_orchestration_service_template", 
        TRANSACTION_IDENTIFIER: "EmployeeMasterList",
        TRANSACTION_IDENTIFIER_NAME: "EmployeeMaster",
        HEADER_MAPPINGS: {}
    }
}

export const ROS_EMAIL_SENDER = {
    DEFAULT: "no-reply@taxgenie.in"
}

export const ROS_PAGINATION_DETAILS = {
    TYPE_PAGE: "page",
    TYPE_CURSOR: "cursor",
    DEFAULT_PAGE_PARAM: "page",
    DEFAULT_PAGE_SIZE_PARAM: "pageSize",
    DEFAULT_PAGE_OBJECT_KEY: "body",
} as const;

export const ROS_BASE_CONSTANTS = {
    PUT: "PUT",
    POST: "POST",
    GET: "GET",
    DEFAULT_RESPONSE_KEY_PATH: 'body.data'
}

export const ROS_BASE_API_PATH = "ros";
export const ROS_PAGE_SIZE = 100_000_000;
