import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from "@angular/forms";

@Component({
  selector: 'tg-field-common-validations',
  templateUrl: './field-common-validations.component.html',
  styleUrls: ['./field-common-validations.component.scss']
})
export class FieldCommonValidationsComponent implements OnInit {
  @Input() control!: AbstractControl; // The form control to check for validation errors
  @Input() errorMap!: { [key: string]: string }; // Map of error keys to custom messages (optional)
  @Input() minLength? : number;
  @Input() maxLength? : number;

  constructor() { }

  ngOnInit(): void {
  }

  getErrorMessage(key: string) {
    if (this.errorMap && this.errorMap[key]) {
      return this.errorMap[key];
    }

    // Provide default messages here if no custom message provided
    switch (key) {
      case 'required':
        return 'This field is required';
      case 'minlength':
        return this.minLength ? `Minimum ${this.minLength} characters required` : 'Minimum length is not met.';
      case 'maxlength':
        return this.maxLength ? `Maximum ${this.maxLength} allowed` : 'Maximum length exceed.';
      case 'invalidFormat':
        return 'HSN Code must be numeric';
      case 'invalidLength':
        return 'HSN Code must be 4, 6, or 8 digits long';
      default:
        return;
    }
  }

}
