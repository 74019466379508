import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {

    constructor(private authService: AuthService, private router: Router, private jwtHelper: JwtHelperService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const token = sessionStorage.getItem('pxp_token');

        if (!token || this.jwtHelper.isTokenExpired(token)) {
            this.router.navigate(['/unauthorized']);
            return false;
        }

        const decodedToken = this.jwtHelper.decodeToken(token);
        const userRoles: string[] = decodedToken.permissions.map((perm: any) => {
            return perm.authority.split('Role: ')[1]?.split('(')[0]?.trim();
        });

        const requiredRole = route.data['requiredRole'];

        if (userRoles.includes(requiredRole)) {
            return true;
        } else {
            this.router.navigate(['/unauthorized']);
            return false;
        }
    }
}
