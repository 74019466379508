import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject, forkJoin, map } from 'rxjs';
import { ILookupModel, LookupDetailsViewModel, ServerResponseWithBody } from 'src/app/_models/app.models';
import { IPRItemDetails, ItemCatalogue, ItemTypeResponseData } from 'src/app/_models/pr.models';
import { hsnValidator } from 'src/app/_services/_validate-form/custom-validators';
import { RestService } from 'src/app/_services/rest.service';
import { getUrlPathFragment } from 'src/app/_static/util';

@Component({
  selector: 'tg-purchase-requisition-line-item-view',
  templateUrl: './purchase-requisition-line-item-view.component.html',
  styleUrls: ['./purchase-requisition-line-item-view.component.scss']
})
export class PurchaseRequisitionLineItemViewComponent implements OnInit {
  title!: string;
  id!: number;
  data!: IPRItemDetails;
  items: { label: string, value: string }[] = [];
  itemDetails!: ItemCatalogue[]
  vendorNames!: string[];
  itemTypeResponse!: ItemTypeResponseData[];
  isEdit!: boolean;
  itemIndex!: number;
  itemDetailsForm!: FormGroup;
  isTaxApplicable!: boolean;
  vendorId!: number;

  prLineItemData: Subject<IPRItemDetails> = new Subject();

  supplierList: LookupDetailsViewModel[] = [];
  supplierDictionary: { [key: string]: LookupDetailsViewModel } = {};
  via!: string;
  supplierName!: string;
  unitOfMeasureList: ILookupModel[] = [];
  gstRateList: ILookupModel[] = [];
  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private restService: RestService
  ) { }

  ngOnInit(): void {
    this.getGSTRates();
    this.getUnitOfMeasure();
    this.itemDetailsForm = this.createForm();
    this.getSupplierList()
    if (this.isEdit)
      this.setFormData(this.data);
  }
  setFormData(data: IPRItemDetails) {
    console.log(data)
    this.getItems(data.itemType, data.itemName);
    this.manipulateNonCatalogData(data.isCatalog)
    this.hsnOrSac.setValue(data.hsn);
    this.itemDetailsForm.patchValue(data);
    this.itemDetailsForm.get('gstRate')?.setValue(data.gstRate.toString())
  }
  manipulateNonCatalogData(isCatalog: boolean) {
    if (isCatalog == null) {
      this.itemCategory.setValue('Non Catalog')
    }
    else {
      this.itemCategory.setValue('Catalog')
    }
  }
  getItems(itemType: string, itemName: string) {
    const url = this.vendorId ? getUrlPathFragment('item', itemType, this.vendorId) : getUrlPathFragment('item', itemType);
    this.restService.read<ServerResponseWithBody<ItemTypeResponseData[]>>(url).subscribe(response => {
      this.itemTypeResponse = response.body;

      // Map the response to { label, value } format
      this.items = this.itemTypeResponse.map(item => ({
        label: item.name,     // Displayed label
        value: item.name      // Corresponding value (can be an id or other unique identifier)
      }));

      // Filter for vendorNames as before
      this.vendorNames = this.itemTypeResponse
        .filter(item => item.name === itemName)[0].item
        .map(item => item.vendorName);
    });
  }

  onModalClose() {
    this.bsModalRef.hide();
  }

  loadData() {
    forkJoin([
      this.restService.read
        <ServerResponseWithBody<LookupDetailsViewModel[]>>
        (getUrlPathFragment('itemList')).pipe(map(res => res.body)),
    ]).subscribe(res => {
    })
  }

  createForm(): FormGroup {
    return this.formBuilder.group({
      id: [null],
      itemMasterId: [null],
      itemCategory: [{ value: null, disabled: this.isEdit ? true : false }, Validators.required],
      itemType: [{ value: null, disabled: true }, Validators.required],
      itemName: [{ value: null, disabled: true }, Validators.required],
      vendorId: [{ value: null, disabled: true }, Validators.required],
      vendorName: [null],
      unitOfMeasure: [{ value: null, disabled: true }],
      itemDescription: [{ value: null, disabled: this.isEdit ? true : false }],
      quantity: [null, [Validators.required, Validators.min(0.0001)]],
      unitRate: [{ value: null }, Validators.required],
      gstRate: [{ value: null }, Validators.required],
      isChanged: [false],
      customeDeleteId: [null],
      budgetId: [null],
      hsnOrSac: [{ value: null, disabled: true }, [Validators.required, hsnValidator]],
      itemCode: [{ value: null, disabled: true }],
      discount: [null],
      cessAmt: [null],
      stateCessAmt: [null],
      otherCharges: [null]
    })

  }

  onItemTypeChange(itemType: string): void {

    if (this.itemCategory.value === 'Catalog') {
      // this.resetForm();
      this.vendorNames = [];
      this.itemDetails = [];
      this.items = [];
      this.itemName.setValue(null)
      const url = this.vendorId ? getUrlPathFragment('item', itemType, this.vendorId) : getUrlPathFragment('item', itemType);
      this.restService.read<ServerResponseWithBody<ItemTypeResponseData[]>>(url).subscribe(response => {
        this.itemTypeResponse = response.body
        this.items = this.itemTypeResponse.map(item => ({
          label: item.name,     // Displayed label
          value: item.name      // Corresponding value (can be an id or other unique identifier)
        }));
        this.itemName.setValue(null)
      });
      this.itemName.setValue(null)
      this.itemName.enable();
    }
  }
  onItemChange(name: string) {
    // Reset vendorNames to avoid any previous selection
    this.vendorNames = [];


    // Use setTimeout to re-enable the vendorName control after a short delay

    this.vendorName.enable();
    // Find and update vendorNames based on selected itemName
    const selectedItem = this.itemTypeResponse.find(item => item.name === name);
    if (selectedItem) {
      this.vendorNames = selectedItem.item.map(item => item.vendorName);
      this.itemDetails = selectedItem.item;
    }

    // Patch form values and explicitly set vendorName to null
    this.itemDetailsForm.patchValue({
      vendorName: null, // Reset the vendorName field
      unitOfMeasure: null,
      itemDescription: null,
      quantity: null,
      unitRate: null,
      gstRate: null,
      itemMasterId: null,
      hsnOrSac: null,
      itemCode: null,
      discount: null,
      cessAmt: null,
      stateCessAmt: null,
      otherCharges: null
    });

    this.onVendorChange(this.vendorNames[0]);
  }
  resetForm() {
    this.itemDetailsForm.patchValue({
      itemName: null,
      vendorName: null,
      unitOfMeasure: null,
      itemDescription: null,
      quantity: null,
      unitRate: null,
      gstRate: null,
      itemMasterId: null,
      hsnOrSac: null,
      itemCode: null,
      discount: null,
      cessAmt: null,
      stateCessAmt: null,
      otherCharges: null
    });
  }
  saveItemDetails() {
    this.itemDetailsForm.markAllAsTouched();
    if (this.itemDetailsForm.dirty) {

      console.log("hi")
      this.itemDetailsForm.get('isChanged')?.setValue(true);
    }
    if (this.itemDetailsForm.valid) {

      console.log("hi")
      let itemData: IPRItemDetails = this.itemDetailsForm.getRawValue();
      itemData.hsn = this.hsnOrSac.value;
      itemData.itemIndex = this.itemIndex;
      if (this.itemCategory.value === 'Non Catalog') {
        // Before saving, take the vendorId and search it against the dictionary to get the vendor name.
        const vendorIdKey = String(itemData.vendorId);
        const supplier = this.supplierDictionary[vendorIdKey];
        itemData.vendorName = supplier.value;
      }
      this.prLineItemData.next(itemData)
      this.bsModalRef.hide();
    }
  }
  private logInvalidControls(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.get(key);
      if (control && control.invalid) {
        console.error(`Invalid Control: ${key}`, control.errors);
      }
    });
  }

  onVendorChange(name: string): void {
    const selectedItem = this.itemTypeResponse.find(item => item.name === this.itemName.value)
    console.log(selectedItem);
    if (selectedItem) {
      // Find the vendor ID where vendorName matches the given name
      const matchedItem = selectedItem.item.find(item => item.vendorName === name);

      if (matchedItem) {
        this.itemDetailsForm?.get('vendorId')?.setValue(matchedItem.vendorId);
      }
    }
    const itemDetail = this.itemDetails.filter((item) => item.vendorName === name)[0];
    if (itemDetail) {
      this.itemDetailsForm.patchValue({
        unitOfMeasure: itemDetail.unitOfMeasure,
        itemDescription: itemDetail.description,
        quantity: itemDetail.quantity,
        unitRate: itemDetail.rate,
        gstRate: itemDetail.gstRate.toString(),
        itemMasterId: itemDetail.id,
        vendorName: itemDetail.vendorName,
        hsnOrSac: itemDetail.hsnOrSac,
        itemCode: itemDetail.itemCode,
      });
    }
  }
  getTooltipForItem(item: string): string {
    return item;
  }

  get itemType() {
    return this.itemDetailsForm.get('itemType') as FormControl;
  }
  get itemName() {
    return this.itemDetailsForm.get('itemName') as FormControl;
  }
  get vendorName() {
    return this.itemDetailsForm.get('vendorName') as FormControl;
  }
  get unitOfMeasure() {
    return this.itemDetailsForm.get('unitOfMeasure') as FormControl;
  }
  get itemDescription() {
    return this.itemDetailsForm.get('itemDescription') as FormControl;
  }
  get quantity() {
    return this.itemDetailsForm.get('quantity') as FormControl;
  }
  get unitRate() {
    return this.itemDetailsForm.get('unitRate') as FormControl;
  }
  get gstRate() {
    return this.itemDetailsForm.get('gstRate') as FormControl;
  }
  get igstAmount() {
    return this.itemDetailsForm.get('igstAmount') as FormControl;
  }
  get cgstAmount() {
    return this.itemDetailsForm.get('cgstAmount') as FormControl;
  }
  get sgstAmount() {
    return this.itemDetailsForm.get('sgstAmount') as FormControl;
  }
  get hsnOrSac() {
    return this.itemDetailsForm.get('hsnOrSac') as FormControl;
  }
  get itemCode() {
    return this.itemDetailsForm.get('itemCode') as FormControl;
  }
  get discount() {
    return this.itemDetailsForm.get('discount') as FormControl;
  }
  get cessAmt() {
    return this.itemDetailsForm.get('cessAmt') as FormControl;
  }
  get stateCessAmt() {
    return this.itemDetailsForm.get('stateCessAmt') as FormControl;
  }
  get otherCharges() {
    return this.itemDetailsForm.get('otherCharges') as FormControl;
  }
  get itemCategory() {
    return this.itemDetailsForm.get('itemCategory') as FormControl;
  }
  onItemCategoryChange(category: string) {
    this.resetForm();
    this.vendorNames = [];
    this.itemDetails = [];
    this.items = [];
    if (category === 'Non Catalog' && this.via === 'PR') {
      this.vendorName.enable();
      this.itemType.reset();
      this.itemDetailsForm.enable();
      this.itemType.enable();
      this.itemDetailsForm.get('vendorId')?.removeValidators([Validators.required])
    }
    else if (category === 'Non Catalog' && this.via === 'PO') {
      this.itemDetailsForm.enable();
      this.itemType.reset();
      this.vendorName.setValue(this.supplierName)
      this.vendorName.disable();
      this.itemDetailsForm.get('vendorId')?.setValue(this.vendorId);
      this.itemType.enable();
    }
    else {
      this.itemDetailsForm.reset()
      this.itemCategory.setValue(category)
      this.itemName.disable();
      this.itemType.reset();
      this.itemType.enable();
      this.vendorName.disable();
      this.unitOfMeasure.disable();
      this.hsnOrSac.disable();
      this.itemCode.disable();
      this.itemDetailsForm.get('vendorId')?.reset();
    }
  }
  private getSupplierList() {
    this.restService.read<ServerResponseWithBody<LookupDetailsViewModel[]>>
      (getUrlPathFragment('company', 'supplier-names')).pipe(map(res => res.body || []))
      .subscribe((suppliers: LookupDetailsViewModel[]) => {
        // this.supplierList = [{
        //   id: null,
        //   value: '--NA--'
        // }, ...suppliers]
        //   ;
        this.supplierList = suppliers
        // Convert the array to a dictionary keyed by supplier id.
        const supplierDictionary = suppliers.reduce((dict: { [key: string]: LookupDetailsViewModel }, supplier: LookupDetailsViewModel) => {
          dict[supplier.id as any] = supplier; // keys are converted to strings automatically
          return dict;
        }, {} as { [key: string]: LookupDetailsViewModel });

        supplierDictionary['null'] = { id: null, value: '--NA--' };
        this.supplierDictionary = supplierDictionary;
      })
  }
  getGSTRates() {
    this.restService.read<ServerResponseWithBody<ILookupModel[]>>(
      getUrlPathFragment('lookup', 'GST')
    ).subscribe((res) => {
      this.gstRateList = res.body
    })
  }
  getUnitOfMeasure() {
    this.restService.read<ServerResponseWithBody<ILookupModel[]>>(
      getUrlPathFragment('lookup', 'UNIT_OF_MEASURE')
    ).subscribe((res) => {
      this.unitOfMeasureList = res.body
    })
  }
}
