import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';
import { IInvoiceTATIdentifiersRequestViewModel, IInvoiceTATIdentifiersResponseViewModel } from 'src/app/_models/reports.models';
import { ServerResponseWithBody } from 'src/app/_models/app.models';
import { getUrlPathFragment } from 'src/app/_static/util';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardToInvoiceService {
  reportType!: string;
  aging!: string;
  value1!: string;
  value2!: string;

  constructor(private _restService: RestService) { }

  public getReportType(): string {
    return this.reportType;
  }

  public setReportType(reportType: string): void {
    this.reportType = reportType;
  }

  public getAging(): string {
    return this.aging;
  }

  public setAging(aging: string): void {
    this.aging = aging;
  }

  public getValue1(): string {
    return this.value1;
  }

  public setValue1(value1: string): void {
    this.value1 = value1;
  }

  public getValue2(): string {
    return this.value2;
  }

  public setValue2(value2: string): void {
    this.value2 = value2;
  }

  public getDocumentIdentifiersForReport(): Observable<string[]> {
    return this._restService.post<IInvoiceTATIdentifiersRequestViewModel, ServerResponseWithBody<IInvoiceTATIdentifiersResponseViewModel>>(
      getUrlPathFragment('dashboards', 'invoice-ageing-report', 'drilldown', 'items'),
      this.createInterval()
    )
    .pipe(
      map((res) => res.body),
      map((res) => res.documentIdentifiers)
    );
  }

  private createInterval(): IInvoiceTATIdentifiersRequestViewModel {
    if (this.aging) {
      // Remove " Days" part
      const cleanedRange = this.aging.replace(' Days', '').trim();
      let minTat: number | null = null;
      let maxTat: number | null = null;
    
      if (cleanedRange.includes('-')) {
        // Case: "10-20" → Split normally
        [minTat, maxTat] = cleanedRange.split('-').map(Number);
      } else if (cleanedRange.startsWith('>')) {
        // Case: ">45" → Set maxTat to 45, minTat remains null
        minTat = Number(cleanedRange.substring(1));
      }
    
      return {
        minTat,
        maxTat,
        approverType: this.reportType,
        ...(this.value1 && this.value2 ? { metadataFilters: { [this.value1]: this.value2 } } : {})
      };
    } else {
      return {} as IInvoiceTATIdentifiersRequestViewModel;
    }
  }

}
