import { Injectable } from '@angular/core';
import { RestService } from '../rest.service';

import { getUrlPathFragment } from 'src/app/_static/util';
import { IGeneratePoPdfViewModel, IPODetailsViewModelResponse } from 'src/app/components/purchase-order/models/purchase-order.model';
import { BehaviorSubject, combineLatest, map, tap, Observable, switchMap, of } from 'rxjs';
import { ServerResponseWithBody } from 'src/app/_models/app.models';
import { DOCUMENT_TYPE } from 'src/app/_constants/document-constants';
import { IGeneratePrPdfViewModel, IPRDetailsViewModelResponse } from 'src/app/_models/pr.models';
import { IGeneratePdfRequest, IPDFModel } from 'src/app/_models/pdf.models';

@Injectable({
  providedIn: 'root'
})
export class PdfDownloadService {

  constructor(private _restService: RestService) { }

  // getPdfBase64(id: number, type: string): Observable<IPDFModel> {
  //   if (type === DOCUMENT_TYPE.PO) {
  //     return this.getPOFormData(id)
  //     .pipe(
  //       map((res) => this.mapPoToPdf(res)),
  //       map((res) => {
  //         return {
  //             type: type,
  //             dataJson: res
  //           } as IGeneratePdfRequest
  //       }),
  //       switchMap((res) => this._restService.post<IGeneratePdfRequest ,ServerResponseWithBody<IPDFModel>>(
  //         getUrlPathFragment('pdf'), res,
  //       )),
  //       map((res) => res.body)
  //     )
  //   } else if (type === DOCUMENT_TYPE.PR) {
  //     return this.getPRFormData(id)
  //     .pipe(
  //       map((res) => this.mapPrToPdf(res)),
  //       map((res) => {
  //         return {
  //             type: type,
  //             dataJson: res
  //           } as IGeneratePdfRequest
  //       }),
  //       switchMap((res) => this._restService.post<IGeneratePdfRequest ,ServerResponseWithBody<IPDFModel>>(
  //         getUrlPathFragment('pdf'), res,
  //       )),
  //       map((res) => res.body)
  //     )
  //   }
  //   return of();
  // }

  getPdfBase64(id: number, type: string): Observable<IPDFModel> {
    return this._restService.post<{ documentApprovalContainerId: number, type: string }, ServerResponseWithBody<IPDFModel>>(
      getUrlPathFragment('pdf'),
      { documentApprovalContainerId: id, type: type }
    ).pipe(
      map((res) => res.body)
    );
  }
  

  private getPOFormData(id: number): Observable<IPODetailsViewModelResponse> {
    return this._restService.read<ServerResponseWithBody<IPODetailsViewModelResponse>>(
      getUrlPathFragment('po', 'by-document-container-id', id)
    ).pipe(map(res => res.body));
  }

  private getPRFormData(id: number): Observable<IPRDetailsViewModelResponse> {
      return this._restService.read<ServerResponseWithBody<IPRDetailsViewModelResponse>>(
        getUrlPathFragment('pr', 'by-document-container-id', id)
      ).pipe(map(res => res.body));
    }

    /**
   * Maps an IPODetailsViewModelResponse object to an IGeneratePoPdfViewmodel
   *
   * @param po The IPODetailsViewModelResponse object
   * @returns An IGeneratePoPdfViewmodel object
   */
  private mapPoToPdf(po: IPODetailsViewModelResponse): IGeneratePoPdfViewModel {
    return {
      // PO / Document details
      orderNo: po.poDetails?.orderNo ?? '',
      documentDate: po.poDetails?.documentDate ?? '',

      // Vendor details
      supplierCompanyName: po.vendorDetails?.supplierCompanyName ?? '',
      supplierGstin: po.vendorDetails?.gstin ?? '',
      supplierAddress: [
        po.vendorDetails?.address1,
        po.vendorDetails?.address2
      ]
        .filter(Boolean) // remove any undefined or empty values
        .join(', '),

      // Line items (items on PO)
      itemList: po.itemDetails?.map((item) => ({
        itemDescription: item.itemDescription ?? '',
        quantity: item.selectedQuantity ?? 0,
        unitRate: item.unitRate ?? 0,
        total: item.total ?? 0,
        gstRate: item.gstRate ?? 0,
        totalGstAmount: item.totalGstAmount ?? 0,
        lineTotal: item.total ?? 0,
      })) ?? [],

      totalPOValue: po.poDetails.totalPOValue,
      figureInWords: "",

      // Buyer / Billing details
      billingAddress: [
        po.buyerDetails?.address1,
        po.buyerDetails?.streetName,
        po.buyerDetails?.location,
        po.buyerDetails?.district,
        po.buyerDetails?.pincode
      ]
        .filter(Boolean)
        .join(', '),
      buyerContactPerson: po.buyerDetails?.buyerContactPerson ?? '',
      buyerContactNo: po.buyerDetails?.phoneNumber ?? '',

      // Shipping details
      shippingAddress: po.buyerDetails?.deliveryAddress ?? '',
      shipToContactPerson: po.buyerDetails?.buyerContactPerson ?? '', // or another field if you have a different shipping contact
      shipToContactNo: po.buyerDetails?.phoneNumber ?? '',            // same note as above

      // Segment / Distribution details
      segment: po.segmentDetails?.segmentCode ?? '',
      costCenter: po.segmentDetails?.costCenter ?? '',
      profitCenter: po.segmentDetails?.profitCenter ?? '',
      distribution: po.segmentDetails?.distribution ?? '',
      segmentAmount: po.segmentDetails?.segmentAmount ?? 0,
    };
  }

  private mapPrToPdf(pr: IPRDetailsViewModelResponse): IGeneratePrPdfViewModel {
    // Todo: Fill up the remaining map after the intergration is done 
    return {
      documentDate: pr.prDetails.documentDate,
      description: pr.prDetails.description
    }

  }
}
