import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GetUserRoleForEmployeeRequest, ServerResponseWithBody, UserRoleForEmployeeRequest } from '../_models/app.models';
import { IAuthenticationRequestError, ISignUpEligibilityDTO, ISignUpEligibilityRequest, ISignUpExistingUserRequest, ISignUpRequestModel, IUserCompanyDetails, IUserDetails } from '../_models/cam.models';
import { getUrlPathFragment, mergeHeaders } from '../_static/util';

@Injectable({
  providedIn: 'root'
})
export class CamRestService {

  constructor(private http: HttpClient) { }

  read<T>(urlFragment: string, options?: { headers?: HttpHeaders }): Observable<T> {
    const headers = mergeHeaders(options?.headers);
    return this.http.get<T>(`${environment.camUrl}${urlFragment}`, { headers });
  }

  post<TIn, TOut>(
    urlFragment: string,
    data: TIn,
    options?: { headers?: HttpHeaders }
  ): Observable<TOut> {
    const headers = mergeHeaders(options?.headers);
    return this.http.post<TOut>(`${environment.camUrl}${urlFragment}`, data, { headers });
  }

  // Common Api's

  submitSignUpAndSendVerificationEmailForExistingUser(request: ISignUpExistingUserRequest, options?: { headers?: HttpHeaders }): Observable<ServerResponseWithBody<any>> {
    return this.post<ISignUpExistingUserRequest, ServerResponseWithBody<any>>(
      getUrlPathFragment('auth', 'signup/existingUser'), request
    )
  }

  submitSignUpAndSendVerificationEmail(request: ISignUpRequestModel, options?: { headers?: HttpHeaders }): Observable<ServerResponseWithBody<any>> {
    return this.post<ISignUpRequestModel, ServerResponseWithBody<any>>(
      getUrlPathFragment('auth', 'signup'), request
    )
  }

  checkUserExists(email: string, options?: { headers?: HttpHeaders }): Observable<boolean> {
    return this.read<IUserDetails | IAuthenticationRequestError>(getUrlPathFragment('user', `getuserbyname?userName=${email}`), options)
      .pipe(
        switchMap((res) => {
          if (this.isError(res)) {
            return of(false);
          }
          return of(true);
        }
        ));
  }

  getPanDetails(panNumber: string, options?: { headers?: HttpHeaders }): Observable<IUserCompanyDetails> {
    return this.read<IUserCompanyDetails>(getUrlPathFragment('co', `getbypan?panNumber=${panNumber}`), options);
  }

  performSignUpEligibilityChecks(request: ISignUpEligibilityRequest, options?: { headers?: HttpHeaders }): Observable<ISignUpEligibilityDTO> {
    return this.post<ISignUpEligibilityRequest, ISignUpEligibilityDTO>(getUrlPathFragment(
      'auth', 'signup', 'checks'
    ), request, options);
  }


  private isError(obj: any): obj is IAuthenticationRequestError {
    return 'debugMessage' in obj && typeof obj.errorCode === 'string'
      && 'status' in obj && typeof obj.status === 'string'
      && 'message' in obj && typeof obj.message === 'string';
  }

  getUserSignUpDetails(request: any, options?: { headers?: HttpHeaders }): Observable<any> {
    return this.post<any, any>(
      getUrlPathFragment('auth', 'getusersignupdetails'), request
    )
  }

  readCamVerfication<T>(urlFragment: string, options?: { headers?: HttpHeaders }): Observable<T> {
    return this.http.get<T>(`${environment.CAM_API_URL}${urlFragment}`);
  }

  uploadCompanyProfile(req: any): Observable<any> {
    console.log(req)
    return this.http.post<any>(
      `${environment.CAM_API_URL}${'co/logoupload'}`, req
    )
  }



  getCompanyProfileImage(companyId: number, options?: { headers?: HttpHeaders }): Observable<any> {
    return this.http.get(`${environment.CAM_API_URL}co/getlogo?companyId=${companyId}`, options);
  }

  removeCompanyProfileImage(companyId: number): Observable<any> {
    return this.http.put<any>(`${environment.CAM_API_URL}co/removelogo?companyId=${companyId}`, null);
  }

  uploadUserProfile(req: any): Observable<any> {
    return this.http.post<any>(
      `${environment.CAM_API_URL}${'user/photoupload'}`, req
    )
  }

  removeUserProfileImage(userEmail: string): Observable<any> {
    return this.http.put<any>(`${environment.CAM_API_URL}user/removephoto?username=${userEmail}`, null);
  }

  getUserProfileImage(userEmail: string, options?: { headers?: HttpHeaders }): Observable<any> {
    return this.http.get(
      `${environment.CAM_API_URL}/user/getphoto?username=${userEmail}`,
      options
    );
  }
  getUserRoleAccess(request: GetUserRoleForEmployeeRequest): Observable<any> {
    return this.http.post(
      `${environment.CAM_API_URL}/user/getuserrolesforcompanyforproduct`, request
    );
  }
  saveUserRoleAccess(request: UserRoleForEmployeeRequest): Observable<any> {
    return this.http.post(
      `${environment.CAM_API_URL}/user/updateuserrolesforcompanyforproduct`, request
    );
  }
}
