import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Custom Validator for HSN Code
 * - Sets default value to 0 if field is empty.
 * - Validates that the HSN is numeric and has a length of 4, 6, or 8.
 */
export function hsnValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;

    // If no value is provided, set it to 0
    if (value === null || value === undefined || value === '') {
        // control.setValue('0', { emitEvent: false });
        return null;
    }

    // Check if the value is numeric
    const numericRegex = /^\d+$/;
    if (!numericRegex.test(value)) {
        return { invalidFormat: 'HSN Code must be numeric.' };
    }

    // Check the length
    const validLengths = [4, 6, 8];
    if (!validLengths.includes(value.length)) {
        if (value === '0') {
            return null
        }
        else {
            return { invalidLength: 'HSN Code must be 4, 6, or 8 digits long.' };
        }
    }

    return null; // Valid HSN
}
