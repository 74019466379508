<div class="flex-between-v-center px-4 py-3 cluster-bar">
    <span class="cluster-title">
        Upload Documents
    </span>
    <div class="flex-v-center">
        <i class="bi bi-x-lg pointer" (click)="onClose()"></i>
    </div>
</div>
<div>
    <div class="align-item-center">
        <span (click)="initiateUpload()"><i class="fas fa-cloud-upload-alt me-2"></i> </span>
        <span class="tg-validation-info" (click)="initiateUpload()">Click here to
            Browse documents</span>
        <input (change)="onFileSelected($event) " #fileInput id="attachment" multiple style="display:none"
            accept=".jpg,.png,.pdf,.zip,.xlsx,.csv" type="file">
    </div>
    <ng-container>
        <p-table [value]="files" *ngIf="files.length">
            <ng-template pTemplate="header">
                <tr>
                    <th>Document Name</th>
                    <th>Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-file>
                <tr>
                    <td>{{ file.name }}</td>
                    <td>
                        <button class="remove" type="button" pButton icon="pi pi-trash"
                            (click)="removeFile(file)"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </ng-container>
</div>
<div class="d-flex justify-content-center py-3 ">
    <button class="preview-button" (click)="upload(id)">
        Upload</button>
    <!-- <button class="query-button" *ngIf="canQuery"><i class="bi bi-chat-left-dots"></i> Raise Query</button> -->
</div>